<template>
  <div>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <div class="game-banner" v-if="$vuetify.breakpoint.xsOnly">
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img class="align-end" :aspect-ratio="360 / 140" :src="`${mediaUrl}/game_banner/Top_Banner_Mobile.jpg`"></v-img>
    </div>
    <div class="game-banner" v-else style="position: relative;">
      <!-- <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
              <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
          </v-carousel-item>
      </v-carousel> -->
      <v-img class="align-end" :aspect-ratio="1800 / 350" :src="`${mediaUrl}/game_banner/Top_Banner_Desktop.jpg`"></v-img>
      <div class="text-vip">
        <p>
          {{ $t(`label.joinVip1`) }}
          <span style="color: #FF860C;">{{ $t(`label.joinVip2`) }}</span>
          {{ $t(`label.joinVip3`) }}
        </p>
        <p style="margin-bottom: 0px;font-size: 16px; ">{{ $t(`label.vip1`) }}</p>
        <p style="font-size: 16px; ">{{ $t(`label.vip2`) }}</p>
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.xsOnly" style="background: linear-gradient(to top right, #000000, #005A54);">
      <div class="vip-tier">
        <h1 class="viptitle">{{ $t(`label.benefitasvip`) }}</h1>
        <div>
          <p class="title2">{{ $t(`label.currentstatus`) }}</p>
          <v-progress-linear class="mt-3 customviptierprogress" width="95%" height="10" color="#F26A21" :value="currentProgress" rounded>
            <template v-slot:progress>
              <div v-for="(progress, index) in progressLevels" :key="index" :class="['v-progress-linear__progress__segment', 'tier-' + progress.toLowerCase()]">
                {{ progress }}
              </div>
            </template>
          </v-progress-linear>

          <div style="display: flex;">
            <v-avatar v-for="tier in tiers" :key="tier.name" tile class="tier-all-png">
              <p class="linesmobile" style="order: 1;">|</p>
              <v-img :src="`/static/svg/vipassets/${tier.name}.png`" style="order: 2;"></v-img>
              <p class="pp" :class="[tier.name]" style="order: 3;">{{ capitalizedTierName(tier.name) }}</p>
            </v-avatar>
          </div>
        </div>
        <v-row no-gutters class="MB-row" style="height: 230px;margin-top: 5%;">
          <v-col cols="5" class="MB-left-col pr-4" style="display: flex; align-items: center; padding-bottom: 10%;">
            <v-row no-gutters>
              <v-col cols="12">
                <p class="title3">{{ $t(`label.vipprivileges`) }}</p>
              </v-col>
              <v-col cols="12" class="progress-details-col">
                <span>{{ $t(`label.lv`) }} {{ level }} {{ textColorUppercase() }}</span>
                <br />
                <!--            <span>{{ progressRequired }} {{$t(`label.moreToNextLvl`)}}</span><br/>-->
                <!--            <span>{{$t(`label.lv`) }} {{nextLv}}</span>-->
              </v-col>
            </v-row>
          </v-col>
          <!--   first draft design   -->
          <!--      <v-col cols="6" class="nav-container">-->
          <!--        <div class="mx-auto nav-wrapper">-->
          <!--          <v-card class="nav-item" :to="{ name: routeName.VIP }">-->
          <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/bettingPass.svg`" />-->
          <!--          </v-card>-->
          <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.bettingPass`) }}</span>-->
          <!--        </div>-->
          <!--        <div class="mx-auto nav-wrapper">-->
          <!--          <v-card class="nav-item" :to="{ name: routeName.REWARDS }">-->
          <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/rewards.svg`" />-->
          <!--          </v-card>-->
          <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.rewards`) }}</span>-->
          <!--        </div>-->
          <!--        <div class="mx-auto nav-wrapper">-->
          <!--          <v-card class="nav-item" :to="{ name: routeName.TRANSACTION_HISTORY }">-->
          <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/betHistory.svg`" />-->
          <!--          </v-card>-->
          <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.betHistory`) }}</span>-->
          <!--        </div>-->
          <!--        <div class="mx-auto nav-wrapper">-->
          <!--          <v-card class="nav-item" :to="{ name: routeName.WITHDRAWAL }">-->
          <!--            <img class="img-nav-item" :src="`/static/svg/mobileMemberBalance/withdrawal.svg`" />-->
          <!--          </v-card>-->
          <!--          <span class="nav-item-span">{{ $t(`mobileMenuTitle.withdrawal`) }}</span>-->
          <!--        </div>-->
          <!--      </v-col>-->
          <!--  2nd draft design    -->
          <v-col cols="7" class="MB-right-col d-flex align-center">
            <v-row class="justify-center align-center">
              <v-btn @click="showPreviousLevel" class="btnleft">
                <img src="/static/svg/vipassets/RightBtn.png" style="transform: scaleX(-1); background-color: transparent;" width="10" height="15" />
              </v-btn>

              <v-avatar tile class="tierpng">
                <!--            <v-img v-if="memberVipTierProgress && memberVipTierProgress.image" :src="`${memberVipTierProgress.image}`"></v-img>-->
                <!--            <v-img v-else :src="`/static/svg/vipassets/vip_${tierColorThemeName}.svg`" eager></v-img>-->
                <v-img :src="`/static/svg/vipassets/vip_${getTextColor()}.png`" eager></v-img>
              </v-avatar>
              <v-btn @click="showNextLevel" class="btnright ">
                <img src="/static/svg/vipassets/RightBtn.png" style="background-color: transparent" width="10" height="15" />
              </v-btn>
              <!--          <div>-->
              <!--            viptiers badge-->
              <!--          </div>-->
            </v-row>
            <!--        <v-row>-->
            <!--          <br/>-->
            <!--          <span style="text-transform: uppercase;text-align: center">{{tierName}}</span>-->
            <!--        </v-row>-->
          </v-col>
        </v-row>
      </div>
      <div>
        <!--      <thead>-->
        <!--      <tr>-->
        <!--        <th>Game Type</th>-->
        <!--        <th>Rebate Percentage</th>-->
        <!--      </tr>-->
        <!--      </thead>-->
        <p class="titlewithbackgroundcolor">{{ $t(`label.vipbenfits`) }}</p>
        <table style="width: 100%; padding-bottom: 20px;">
          <tbody>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.slotrebate`) }}</td>
              <td class="righttext noborder">{{ slotpercentage }}</td>
            </tr>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.casinorebate`) }}</td>
              <td class="righttext noborder">{{ casinopercentage }}</td>
            </tr>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.sportrebate`) }}</td>
              <td class="righttext noborder">{{ sportpercentage }}</td>
            </tr>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.freeCoins`) }}</td>
              <td class="righttext noborder">{{ freeCoinspercentage }}</td>
            </tr>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.upgradebonus`) }}</td>
              <td class="righttext noborder">{{ upgradebonus }}</td>
            </tr>

            <tr>
              <td class="lefttext noborder">{{ $t(`label.monthlyLuckyDraw`) }}</td>
              <td class="righttext noborder">{{ monthlyLuckyDrawses }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="upgrade-requirement">
        <p class="titlewithbackgroundcolor">{{ $t(`label.upgradereq`) }}</p>
        <table style="width: 100%; padding-bottom: 20px;">
          <tbody>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.upgradedepreq`) }}</td>
              <td class="righttext noborder">{{ depositrequirement }}</td>
            </tr>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.upgradeturnreq`) }}</td>
              <td class="righttext noborder">{{ turnoverrequirement }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="retention-requirement">
        <p class="titlewithbackgroundcolor">{{ $t(`label.retentionreq`) }}</p>
        <table style="width: 100%; padding-bottom: 20px;">
          <tbody>
            <tr>
              <td class="lefttext noborder">{{ $t(`label.downcriteria`) }}</td>
              <td class="righttext noborder">{{ downgradecriteria }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-col cols="12" style="padding: 0px !important;">
        <v-divider class="custom-divider"></v-divider>
      </v-col>
      <div class="be-kk-vip">
        <h1 style="font-size: 24px !important; font-weight: normal !important;" class="headertext">{{ $t(`label.beVip`) }}</h1>
        <p style="font-size: 14px !important;">{{ $t(`label.beVipDes`) }}</p>
        <!--        <p style="font-size: 14px !important;">{{ $t(`label.vip2`) }}</p>-->
        <!--        <p>{{ $t(`label.vip2`) }}</p>-->
      </div>
      <v-col cols="12" style="padding: 0px !important;">
        <v-divider class="custom-divider"></v-divider>
      </v-col>
      <div class="vip-level-upgrade-info">
        <div class="vip-level-upgrade-info-inner">
          <h1 class="headertext">{{ $t(`label.viplevelupgrade`) }}</h1>
          <p style=" color:orange; text-align: left;">{{ $t(`label.howtojointitle`) }}</p>

          <p>1. {{ $t(`label.howtojoindes1`) }}</p>
          <p>2. {{ $t(`label.howtojoindes2`) }}</p>
          <p>3. {{ $t(`label.howtojoindes3`) }}</p>
          <p>4. {{ $t(`label.howtojoindes4`) }}</p>
          <p>5. {{ $t(`label.howtojoindes5`) }}</p>
        </div>
      </div>
      <v-col cols="12" style="padding: 0px !important;">
        <v-divider class="custom-divider"></v-divider>
      </v-col>
      <div class="faq-tnc-container">
        <v-btn class="tncbtn" width="100%" style="text-transform: none !important;" @click="toggleTnc" color="primary">
          <span class="faqspan">{{ $t(`label.tnctitle`) }}</span>
          <div :class="['triangle', showTnc ? 'down' : 'up']"></div>
        </v-btn>
        <v-expand-transition>
          <div v-if="showTnc">
            <p class="pp">1. {{ $t(`label.tncviptier1`) }}</p>
            <p class="pp">2. {{ $t(`label.tncviptier2`) }}</p>
            <p class="pp">3. {{ $t(`label.tncviptier3`) }}</p>
            <p class="pp">4. {{ $t(`label.tncviptier4`) }}</p>
            <p class="pp">5. {{ $t(`label.tncviptier5`) }}</p>
            <p class="pp">6. {{ $t(`label.tncviptier6`) }}</p>
            <p class="pp">7. {{ $t(`label.tncviptier7`) }}</p>
          </div>
        </v-expand-transition>
        <v-btn class="faqbtn" width="100%" style="text-transform: none !important;" @click="toggleFaq" color="primary">
          <span class="faqspan">{{ $t(`label.faqtitle`) }}</span>
          <div :class="['triangle', showFaq ? 'down' : 'up']"></div>
        </v-btn>
        <v-expand-transition>
          <div v-if="showFaq">
            <p class="pp">Q1. {{ $t(`label.faqviptier1`) }}</p>
            <p class="pp">A1. {{ $t(`label.faqviptier1des`) }}</p>
            <p class="pp">Q2. {{ $t(`label.faqviptier2`) }}</p>
            <p class="pp">A2. {{ $t(`label.faqviptier2des`) }}</p>
            <p class="pp">Q3. {{ $t(`label.faqviptier3`) }}</p>
            <p class="pp">A3. {{ $t(`label.faqviptier3des`) }}</p>
            <p class="pp">Q4. {{ $t(`label.faqviptier4`) }}</p>
            <p class="pp">A4. {{ $t(`label.faqviptier4des`) }}</p>
            <p class="pp">Q5. {{ $t(`label.faqviptier5`) }}</p>
            <p class="pp">A5. {{ $t(`label.faqviptier5des`) }}</p>
            <p class="pp">Q6. {{ $t(`label.faqviptier6`) }}</p>
            <p class="pp">A6. {{ $t(`label.faqviptier6des`) }}</p>
            <p class="pp">Q7. {{ $t(`label.faqviptier7`) }}</p>
            <p class="pp">A7. {{ $t(`label.faqviptier7des`) }}</p>
            <p class="pp">Q8. {{ $t(`label.faqviptier8`) }}</p>
            <p class="pp">A8. {{ $t(`label.faqviptier8des`) }}</p>
            <p class="pp">Q9. {{ $t(`label.faqviptier9`) }}</p>
            <p class="pp">A9. {{ $t(`label.faqviptier9des`) }}</p>
          </div>
        </v-expand-transition>
        <!--    </div>-->
        <!--    <div class="tnc-container">-->
      </div>
    </div>
    <div v-else style="background: linear-gradient(to top right, #000000, #005A54);">
      <div class="vip-tierdesktop">
        <h1 class="titledesktop">{{ $t(`label.benefitasvip`) }}</h1>
        <v-container style="max-width: 1900px;">
          <v-row no-gutters class="MB-row" style="height: 320px;">
            <v-col cols="3" class="MB-left-col" style="display: flex; align-items: center;">
              <v-row no-gutters class="justify-center">
                <img src="/static/svg/vipassets/vipcard_asset.png" style="max-width: 90%;" />
              </v-row>
            </v-col>
            <v-col cols="9" class="MB-right-col d-flex align-center" style="height: 200px;">
              <v-row>
                <v-col cols="12">
                  <p class="title2">{{ $t(`label.currentstatus`) }}</p>
                </v-col>
                <v-col cols="12">
                  <v-progress-linear style=" border-radius: 10px;" class="mt-3" height="18" color="#F26A21" :value="DesktopcurrentProgress" rounded>
                    <template v-slot:progress>
                      <div v-for="(progress, index) in progressLevels" :key="index" :class="['v-progress-linear__progress__segment', 'tier-' + progress.toLowerCase()]">
                        {{ progress }}
                      </div>
                    </template>
                  </v-progress-linear>
                  <br />
                </v-col>
                <v-col cols="12" class="align-center d-flex justify-center">
                  <v-col cols="2" class="align-center d-flex justify-center pa-0" v-for="tier in tiers" :key="tier.name">
                    <v-avatar class="tier-all-png-desktop">
                      <p class="lines" style="order: 1;">|</p>
                      <v-img :src="`/static/svg/vipassets/${tier.name}.png`" class="tier-img" style="order: 2; height: 90px; width: 90px;"></v-img>
                      <p class="pp" :class="[tier.name]" style="order: 3; font-size: 16px; margin-top: 10px;">
                        {{ capitalizedTierName(tier.name) }}
                      </p>
                    </v-avatar>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-row>
        <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important;">
          <v-divider class="custom-divider"></v-divider>
        </v-col>
      </v-row>
      <!--      <div class="vip-tierdesktop height-of-tier" >-->
      <!--        <h1 class="titledesktop">{{ $t(`label.benefitasvip`) }}</h1>-->
      <!--        <v-row class="d-flex align-center justify-center">-->
      <!--          <v-col cols="3">-->
      <!--            <img src="/static/svg/vipassets/vipcard_asset.png" width="90%" />-->
      <!--          </v-col>-->
      <!--          <v-col cols="9">-->
      <!--            <p class="title2">{{ $t(`label.currentstatus`) }}</p>-->
      <!--            <v-progress-linear style=" border-radius: 10px;" class="mt-3" height="18" color="#F26A21" :value="DesktopcurrentProgress" rounded>-->
      <!--              <template v-slot:progress>-->
      <!--                <div v-for="(progress, index) in progressLevels" :key="index" :class="['v-progress-linear__progress__segment', 'tier-' + progress.toLowerCase()]">-->
      <!--                  {{ progress }}-->
      <!--                </div>-->
      <!--              </template>-->
      <!--            </v-progress-linear><br/>-->

      <!--            <div style="display: flex; flex-wrap: wrap;">-->
      <!--              <v-col cols="2" class="pa-0 " v-for="tier in tiers" :key="tier.name">-->
      <!--                <v-avatar class="tier-all-png-desktop">-->
      <!--                  <p class="lines" style="order: 1;">|</p>-->
      <!--                  <v-img :src="`/static/svg/vipassets/${tier.name}.png`" class="tier-img" style="order: 2; height: 90px; width: 90px;"></v-img>-->
      <!--                  <p class="pp" :class="[capitalizeFirstWord(tier.name)]" style="order: 3; font-size: 16px; margin-top: 10px;"> {{capitalizeFirstWord(tier.name)}}</p>-->
      <!--                </v-avatar>-->
      <!--              </v-col>-->
      <!--            </div>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </div>-->
      <!--      <v-row>-->
      <!--        <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important;">-->
      <!--          <v-divider class="custom-divider"></v-divider>-->
      <!--        </v-col>-->
      <!--      </v-row>-->

      <div class="vip-tierdesktop">
        <v-row no-gutters class="MB-row" style="height: auto;">
          <v-col cols="3" class="MB-left-col" style="display: flex; align-items: center;">
            <v-row no-gutters>
              <p style="font-size: 18px; color: #FF860C;padding-left: 20px;">{{ $t(`label.vipprivileges`) }}</p>
            </v-row>
          </v-col>
          <v-col cols="9" class="MB-right-col d-flex align-center" style="height: 200px;">
            <v-col cols="2" class="align-center d-flex justify-center pa-0" v-for="tier in tiers" :key="tier.name">
              <v-avatar class="vip-all-tier-desktop">
                <v-img :src="`/static/svg/vipassets/vip_${tier.name}.png`" style="order: 2;"></v-img>
              </v-avatar>
            </v-col>
            <!--            <v-row class="justify-space-around align-center">-->
            <!--              <v-col cols="2"  v-for="tier in tiers" :key="tier.name">-->

            <!--              </v-col>-->
            <!--              <div style="display: flex; flex-wrap: wrap;">-->
            <!--                <v-avatar v-for="tier in tiers" :key="tier.name" class="vip-all-tier-desktop">-->
            <!--                  <v-img :src="`/static/svg/vipassets/vip_${tier.name}.png`" style="order: 2;"></v-img>-->
            <!--                </v-avatar>-->
            <!--              </div>-->

            <!--            </v-row>-->
            <!--            <v-row class="justify-space-around align-center">-->
            <!--              <v-col cols="2" v-for="tier in tiers" :key="tier.name" >-->
            <!--                <v-avatar class="vip-all-tier-desktop">-->
            <!--                  <v-img :src="`/static/svg/vipassets/vip_${tier.name}.png`" style="order: 2;"></v-img>-->
            <!--                </v-avatar>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="titlewithbackgroundcolor">{{ $t(`label.vipbenfits`) }}</p>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.slotrebate`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in slotpercentages" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.casinorebate`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in casinopercentages" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.sportrebate`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in sportpercentages" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.freeCoins`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in freeCoinspercentages" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.upgradebonus`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in upgradeBonus" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.monthlyLuckyDraw`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in monthlyLuckyDraws" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="titlewithbackgroundcolor">{{ $t(`label.upgradereq`) }}</p>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.upgradedepreq`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in depositReq" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.upgradeturnreq`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in turnoverReq" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="titlewithbackgroundcolor">{{ $t(`label.retentionreq`) }}</p>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="3" class="pa-0">
            <p class="lefttextdesktop noborder pl-2">{{ $t(`label.downcriteria`) }}</p>
          </v-col>
          <v-col cols="9" class="d-flex text-center pa-0">
            <v-col style="padding: 0px !important;" cols="2" v-for="percentage in displayedCriterias" :key="percentage">
              <p class="righttextdesktop noborder">{{ percentage }}</p>
            </v-col>
          </v-col>
        </v-row>
        <!--        <div class="vip-benefits">-->
        <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.sportrebate`) }}</p>-->
        <!--          <table style="width: 100%">-->
        <!--            <tbody>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.slotrebate`) }}</td>-->
        <!--              <td  v-for="percentage in slotpercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.casinorebate`) }}</td>-->
        <!--              <td  v-for="percentage in casinopercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.sportrebate`) }}</td>-->
        <!--              <td  v-for="percentage in sportpercentages" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </div>-->
        <!--        <div class="upgrade-requirement">-->
        <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.upgradereq`) }}</p>-->
        <!--          <table style="width: 100%">-->
        <!--            <tbody>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradedepreq`)}}</td>-->
        <!--              <td  v-for="percentage in depositrequirements" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradeturnreq`)}}</td>-->
        <!--              <td  v-for="percentage in turnoverrequirements" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.upgradebonus`)}}</td>-->
        <!--              <td  v-for="percentage in upgradebonuses" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </div>-->
        <!--        <div class="retention-requirement ">-->
        <!--          <p class="titlewithbackgroundcolor">{{ $t(`label.retentionreq`) }}</p>-->
        <!--          <table style="width: 100%">-->
        <!--            <tbody>-->
        <!--            <tr>-->
        <!--              <td class="lefttextdesktop noborder">{{ $t(`label.downcriteria`)}}</td>-->
        <!--              <td v-for="percentage in downgradecriterias" class="righttextdesktop noborder">{{ percentage }}</td>-->
        <!--            </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </div>-->
      </div>
      <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important;">
        <v-divider class="custom-divider"></v-divider>
      </v-col>
      <!--      <div class="be-kk-vip">-->
      <!--        <h1 style="    font-size: 18px;justify-content: center;display: flex; color: #FF860C; padding-bottom: 20px; text-align: center;">Be A NAGAD88 VIP</h1>-->
      <!--        <p>Nagad88 is an online entertainment brand and a leading specialist of cricket exchange platform in Bangladesh.</p>-->
      <!--        <p>With over 100 games mixed with remarkable sromotions and 24/7 customer support services.</p>-->
      <!--        <p>There are multiple deposit payment methods for Bangladesh such as BKASH, ROCKET, NAGAD and many more to come. Be a Nagad88 VIP and enjoy generous benefits all time long!</p>-->
      <!--      </div>-->
      <!--      <v-col cols="12" style="padding: 0px !important; background-color: #0a0637;">-->
      <!--        <v-divider class="custom-divider"></v-divider>-->
      <!--      </v-col>-->
      <div class="vip-tierdesktop" style="padding-left: 7% !important; padding-right: 7% !important;">
        <div class="vip-level-upgrade-info">
          <div class="vip-level-upgrade-info-inner-desktop">
            <div class="infospace">
              <h1 style=" font-size: 18px;color: #FF860C; text-align: left; padding-bottom: 20px;">{{ $t(`label.viplevelupgrade`) }}</h1>
              <p style=" color:orange; text-align: left;">{{ $t(`label.howtojointitle`) }}</p>

              <p>1. {{ $t(`label.howtojoindes1`) }}</p>
              <p>2. {{ $t(`label.howtojoindes2`) }}</p>
              <p>3. {{ $t(`label.howtojoindes3`) }}</p>
              <p>4. {{ $t(`label.howtojoindes4`) }}</p>
              <p>5. {{ $t(`label.howtojoindes5`) }}</p>
            </div>
          </div>
        </div>
      </div>
      <v-col cols="12" style="padding-left: 8% !important; padding-right: 8% !important;">
        <v-divider class="custom-divider"></v-divider>
      </v-col>
      <div class="vip-tierdesktop" style="padding-left: 7% !important; padding-right: 7% !important;">
        <div class="faq-tnc-container" style="display: flex; justify-content: space-between;">
          <div class="upsideTNC">
            <h2 style="font-size: 18px;display: flex; color: #FF860C; padding-bottom: 20px;">{{ $t(`label.tnctitle`) }}</h2>
            <p class="pp">1. {{ $t(`label.tncviptier1`) }}</p>
            <p class="pp">2. {{ $t(`label.tncviptier2`) }}</p>
            <p class="pp">3. {{ $t(`label.tncviptier3`) }}</p>
            <p class="pp">4. {{ $t(`label.tncviptier4`) }}</p>
            <p class="pp">5. {{ $t(`label.tncviptier5`) }}</p>
            <p class="pp">6. {{ $t(`label.tncviptier6`) }}</p>
            <p class="pp">7. {{ $t(`label.tncviptier7`) }}</p>
          </div>
        </div>
        <div class="faq-tnc-container" style="display: flex; justify-content: space-between;">
          <div class="downsideFAQ">
            <h2 style="font-size: 18px;display: flex; color: #FFFFFF; padding-bottom: 20px;">{{ $t(`label.faqtitle`) }}</h2>
            <p class="pp" style="color: #FF860C;">Q1. {{ $t(`label.faqviptier1`) }}</p>
            <p class="pp">A1. {{ $t(`label.faqviptier1des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q2. {{ $t(`label.faqviptier2`) }}</p>
            <p class="pp">A2. {{ $t(`label.faqviptier2des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q3. {{ $t(`label.faqviptier3`) }}</p>
            <p class="pp">A3. {{ $t(`label.faqviptier3des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q4. {{ $t(`label.faqviptier4`) }}</p>
            <p class="pp">A4. {{ $t(`label.faqviptier4des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q5. {{ $t(`label.faqviptier5`) }}</p>
            <p class="pp">A5. {{ $t(`label.faqviptier5des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q6. {{ $t(`label.faqviptier6`) }}</p>
            <p class="pp">A6. {{ $t(`label.faqviptier6des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q7. {{ $t(`label.faqviptier7`) }}</p>
            <p class="pp">A7. {{ $t(`label.faqviptier7des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q8. {{ $t(`label.faqviptier8`) }}</p>
            <p class="pp">A8. {{ $t(`label.faqviptier8des`) }}</p>
            <p class="pp" style="color: #FF860C;">Q9. {{ $t(`label.faqviptier9`) }}</p>
            <p class="pp">A9. {{ $t(`label.faqviptier9des`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'
import { MEMBER_WALLET, MEMBER_VIP_TIER_PROGRESS } from '@/store/member.module'
export default {
  name: 'viptier',
  components: {
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    showFaq: false,
    showTnc: false,
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    tiers: [
      { name: 'club', color: 'bronze', progress: 16.67 },
      { name: 'diamond', color: 'silver', progress: 33.33 },
      { name: 'heart', color: 'gold', progress: 50 },
      { name: 'spade', color: 'platinum', progress: 66.67 },
      { name: 'ace', color: 'diamond', progress: 83.33 },
      { name: 'raja', color: 'black', progress: 100 }
    ],
    notLoading: true,
    routeName: ROUTE_NAME,
    showProgress: false,
    tierBarBgColor: '',
    tierBarColor: '',
    tierColorThemeName: 'club',
    progressLevels: ['club', 'diamond', 'heart', 'spade', 'ace', 'raja'],
    currentProgress: 0,
    DesktopcurrentProgress: 0,
    tierName: '',
    currentProgressPercentage: 0,
    currentLv: 0,
    level: 1,
    slotpercentages: ['0.10', '0.30', '0.40', '0.50', '1.00', '1.50'],
    casinopercentages: ['0.10', '0.20', '0.30', '0.50', '0.80', '1.00'],
    sportpercentages: ['0.10', '0.20', '0.30', '0.50', '0.80', '1.00'],
    freeCoinspercentages: [1, 1, 1, 2, 5, 8],
    depositrequirements: ['10,000', '500,000', '8,000,000', '20,000,000', '38,888,888', 'Special Invitation'],
    turnoverrequirements: ['80,000', '3,500,000', '60,000,000', '180,000,000', '400,000,000', 'Special Invitation'],
    upgradebonuses: ['200', '5,000', '20,000', '100,000', '400,000', 'Special Invitation'],
    monthlyLuckyDraw: ['N/A', 'N/A', '1 lucky draw ticket', '2 lucky draw ticket', '3 lucky draw ticket', '5 lucky draw ticket'],
    downgradecriterias: ['Inactive for 3 months', 'Inactive for 3 months', 'Inactive for 3 months', 'Inactive for 3 months', 'Inactive for 3 months', 'Special Invitation'],
    levelNames: ['club', 'diamond', 'heart', 'spade', 'ace', 'raja'],
    nextLv: 0,
    progressRequired: 0
  }),
  created() {
    this.getVipTierProgress()
    this.metaTag()
  },
  computed: {
    upgradeBonus() {
      return ['200', '5,000', '20,000', '100,000', '400,000', locale.getMessage('label.specialInvitation')]
    },
    depositReq() {
      return ['10,000', '500,000', '8,000,000', '20,000,000', '38,888,888', locale.getMessage('label.specialInvitation')]
    },
    turnoverReq() {
      return ['80,000', '3,500,000', '60,000,000', '180,000,000', '400,000,000', locale.getMessage('label.specialInvitation')]
    },
    monthlyLuckyDraws() {
      return [
        locale.getMessage('label.na'),
        locale.getMessage('label.na'),
        locale.getMessage('label.1luckyDraw'),
        locale.getMessage('label.2luckyDraw'),
        locale.getMessage('label.3luckyDraw'),
        locale.getMessage('label.5luckyDraw')
      ]
    },
    displayedCriterias() {
      return [
        locale.getMessage('label.inactiveMonths'),
        locale.getMessage('label.inactiveMonths'),
        locale.getMessage('label.inactiveMonths'),
        locale.getMessage('label.inactiveMonths'),
        locale.getMessage('label.inactiveMonths'),
        locale.getMessage('label.specialInvitation')
      ]
    },
    capitalizedTierName() {
      return name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    },
    monthlyLuckyDrawses() {
      const percentageMap = {
        1: locale.getMessage('label.na'),
        2: locale.getMessage('label.na'),
        3: locale.getMessage('label.1luckyDraw'),
        4: locale.getMessage('label.2luckyDraw'),
        5: locale.getMessage('label.3luckyDraw'),
        6: locale.getMessage('label.5luckyDraw')
      }
      return percentageMap[this.level] || ''
    },
    slotpercentage() {
      const percentageMap = {
        1: '0.10%',
        2: '0.30%',
        3: '0.40%',
        4: '0.50%',
        5: '1.00%',
        6: '1.50%'
      }
      return percentageMap[this.level] || ''
    },
    casinopercentage() {
      const percentageMap = {
        1: '0.10%',
        2: '0.20%',
        3: '0.30%',
        4: '0.50%',
        5: '0.80%',
        6: '1.00%'
      }
      return percentageMap[this.level] || ''
    },
    sportpercentage() {
      const percentageMap = {
        1: '0.10%',
        2: '0.20%',
        3: '0.30%',
        4: '0.50%',
        5: '0.80%',
        6: '1.00%'
      }
      return percentageMap[this.level] || ''
    },
    freeCoinspercentage() {
      const percentageMap = {
        1: 1,
        2: 1,
        3: 1,
        4: 2,
        5: 5,
        6: 8
      }

      let currentPrecent = percentageMap[this.level] || ''
      currentPrecent = currentPrecent + '%'
      //mkt request to use english as standard
      // if(currentPrecent!==''){
      //   //convert into locale string
      //   if(this.language==='bd'){
      //     currentPrecent = currentPrecent.toLocaleString('bn-BD')+'%'
      //   }else{
      //     currentPrecent = currentPrecent +'%'
      //   }
      // }

      return currentPrecent
    },
    depositrequirement() {
      const percentageMap = {
        1: '10,000',
        2: '500,000',
        3: '8,000,000',
        4: '20,000,000',
        5: '38,888,888',
        6: locale.getMessage(`label.specialInvitation`)
      }
      return percentageMap[this.level] || ''
    },
    turnoverrequirement() {
      const percentageMap = {
        1: '80,000',
        2: '3,500,000',
        3: '60,000,000',
        4: '180,000,000',
        5: '400,000,000',
        6: locale.getMessage(`label.specialInvitation`)
      }
      return percentageMap[this.level] || ''
    },
    upgradebonus() {
      const percentageMap = {
        1: '200',
        2: '5,000',
        3: '20,000',
        4: '100,000',
        5: '400,000',
        6: locale.getMessage(`label.specialInvitation`)
      }
      return percentageMap[this.level] || ''
    },
    downgradecriteria() {
      const percentageMap = {
        1: locale.getMessage(`label.inactiveMonths`),
        2: locale.getMessage(`label.inactiveMonths`),
        3: locale.getMessage(`label.inactiveMonths`),
        4: locale.getMessage(`label.inactiveMonths`),
        5: locale.getMessage(`label.inactiveMonths`),
        6: locale.getMessage(`label.specialInvitation`)
      }
      return percentageMap[this.level] || ''
    },
    imgSource() {
      return '/static/svg/vipassets/vip_' + this.tierColorThemeName + '.svg'
    },
    formattedValue() {
      const currencySymbol = this.currencySymbolMapp.currencySymbolMap[this.currentCurrency]
      const value = 10000
      return `${currencySymbol}${value}`
    },
    currentDate() {
      return new Date()
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },

    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    totalPoint() {
      return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    referralConfig() {
      return this.$store.state.referral.config
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    webShareApiSupported() {
      return navigator.share
    },
    memberVipTierProgress() {
      return this.$store.state.member.vipTierProgress.pairing
    },
    vipTierUpgrade() {
      return this.$store.state.member.vipTierProgress.upgrade
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview
      this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    },
    redeemCommissionDetailResponseComplete() {
      let response = this.$store.state.referral.redeemCommissionResponse
      if (response.complete) {
        this.redeemCommissionResponseCompleteDialog(response)
        this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
      }
    },
    // memberVipTierProgress() {
    //   let vipTierProgress = this.$store.state.member.vipTierProgress
    //   if (Object.keys(vipTierProgress.pairing).length > 0) {
    //     let curPairing = vipTierProgress.pairing
    //     //progress found
    //     this.showProgress = true
    //     switch (curPairing.level_sequence) {
    //       case 1:
    //         this.tierColorThemeName = 'bronze'
    //         break
    //       case 2:
    //         this.tierColorThemeName = 'silver'
    //         break
    //       case 3:
    //         this.tierColorThemeName = 'gold'
    //         break
    //       case 4:
    //         this.tierColorThemeName = 'platinum'
    //         break
    //       default:
    //         this.tierColorThemeName = 'bronze'
    //         this.tierBarBgColor = '#1867c0'
    //     }
    //     this.tierBarBgColor = 'viptier' + this.tierColorThemeName
    //     this.tierName = curPairing.name
    //     this.currentLv = this.memberVipTGierProgress.level_sequence
    //     this.nextLv = this.memberVipTierProgress.level_sequence + 1
    //   } else {
    //     //no progress
    //     this.showProgress = false
    //   }
    // },
    memberVipTierProgress() {
      let vipTierProgress = this.$store.state.member.vipTierProgress
      if (vipTierProgress && vipTierProgress.pairing) {
        if (Object.keys(vipTierProgress.pairing).length > 0) {
          let curPairing = vipTierProgress.pairing
          this.showProgress = true
          this.tierName = curPairing.name
          this.currentLv = curPairing.level_sequence
          this.nextLv = curPairing.level_sequence + 1

          // Set current progress based on the level_sequence
          switch (curPairing.level_sequence) {
            case 1:
              ;(this.currentProgress = 11), (this.DesktopcurrentProgress = 13)
              break
            case 2:
              ;(this.currentProgress = 27), (this.DesktopcurrentProgress = 27)
              break
            case 3:
              ;(this.currentProgress = 44), (this.DesktopcurrentProgress = 44)
              break
            case 4:
              ;(this.currentProgress = 60), (this.DesktopcurrentProgress = 60)
              break
            case 5:
              ;(this.currentProgress = 78), (this.DesktopcurrentProgress = 78)
              break
            case 6:
              ;(this.currentProgress = 100), (this.DesktopcurrentProgress = 100)
              break
            default:
              ;(this.currentProgress = 0), (this.DesktopcurrentProgress = 0)
          }
        } else {
          this.showProgress = false
        }
      }
    },

    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getVipTierProgress()
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    textColorUppercase() {
      return this.getTextColor().toUpperCase()
    },
    showPreviousLevel() {
      if (this.level > 1) {
        this.level--
      } else {
        this.level = this.levelNames.length
      }
    },
    showNextLevel() {
      if (this.level < this.levelNames.length) {
        this.level++
      } else {
        this.level = 1
      }
    },
    getTextColor() {
      return this.levelNames[this.level - 1] || ''
    },
    getVipTierProgress() {
      this.$store.dispatch(MEMBER_VIP_TIER_PROGRESS)
    },
    toggleFaq() {
      this.showFaq = !this.showFaq
    },
    toggleTnc() {
      this.showTnc = !this.showTnc
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard')
      })
    },
    // copyToClipboard(referralCode) {
    //   const textField = document.createElement('textarea')
    //   textField.innerText = referralCode
    //   document.body.appendChild(textField)
    //   textField.select()
    //   document.execCommand('copy')
    //   textField.remove()
    //   this.isCopied = true
    //   setTimeout(() => { this.isCopied = false }, 3000)
    // },
    getReferralConfig() {
      this.$store.dispatch(`${REFERRAL_GET_CONFIG}`)
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    redeemCommissionConfirmationDialog() {
      let self = this
      if (this.$refs.redeemForm.validate()) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.height-of-tier {
  height: 350px;
}
.text-vip {
  position: absolute;
  top: 50%;
  left: 23%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  text-align: left;
}
.infospace {
  width: 55%;
}
.vip-all-tier-desktop {
  height: 132px !important;
  min-width: 48px !important;
  width: 96px !important;
  border-radius: 0px;
  overflow: inherit;
  margin: 10px 28px 0px;
  display: flex;
  flex-direction: column;
}
.vipcard_asset {
  height: 250px;
  width: 250px;
}
.tierpng {
  height: 182px !important;
  min-width: 48px !important;
  width: 132px !important;
}
.btnleft {
  background-color: transparent !important;
  z-index: 1;
  padding-bottom: 10% !important;
}

.btnright {
  background-color: transparent !important;
  z-index: 1;
  padding-bottom: 10% !important;
}
.title3 {
  font-size: 18px;
  color: #ff860c;
}
.leftside {
  justify-content: center;
  align-items: center;
  display: flex;
}
.downsideFAQ {
  border-radius: 10px;
  width: 100%;
  background: #002b16;
  padding: 20px;
  p {
    font-size: 12px;
  }
}
.upsideTNC {
  border-radius: 10px;
  width: 100%;
  background: #002b16;
  padding: 20px;
  p {
    font-size: 12px;
  }
}

.lines {
  color: #00ffb1;
  font-size: 24px;
  margin-bottom: 10px;
}
.linesmobile {
  justify-content: center;
  display: flex;
  color: #00ffb1;
  font-size: 14px;
  margin-bottom: 10px !important;
}
.v-progress-linear__determinate {
  border-radius: 13px;
  //height: 12px;
}
.rightside {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 8%;
}
.custom-divider {
  padding: 0px !important;
  border-width: 0.8px !important;
  border-color: #00ffb1 !important;
  //border-image: linear-gradient(45deg,  #002A20, #00FFB1) 1;
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
}
.v-progress-linear__background {
  background-color: #00543d !important;
  opacity: 1 !important;
}

.viptitle {
  color: #ff860c;
  text-align: center;
  font-size: 18px;
  //background-color: #0a0637;
}
.titledesktop {
  color: #ffffff;
  text-align: left;
  font-size: 18px;
  //background-color: #0a0637;
  padding-left: 20px;
}
.title2 {
  color: #ff860c;
  font-size: 18px;
  margin-top: 25px;
}

.titlewithbackgroundcolor {
  font-size: 18px;
  color: #ffffff;
  padding-left: 20px;
  background-color: #003b26;
  padding-top: 10px;
  padding-bottom: 10px;
  //margin-left: -20px;
  //margin-right: -20px;
}
.noborder {
  border-bottom: 0px;
}
th,
td {
  padding: 8px;
}

.v-expand-transition p {
  font-size: 12px;
}
.faqbtn,
.tncbtn {
  background: transparent linear-gradient(180deg, #ff860c 0%, #ff860c 100%) 0% 0% no-repeat padding-box;
  justify-content: start;
  margin-bottom: 10px;
}

.triangle {
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #ffffff transparent;
}

.triangle.up {
  transform: translate(-50%, -50%) rotate(180deg);
  top: 60%;
}

.triangle.down {
  transform: translate(-50%, -50%);
  top: 40%;
}
.faq-tnc-container {
  padding: 20px;
  color: white;
}
.vip-tier {
  //background-color: #060936;
  padding: 20px;
  padding-bottom: 0px;
  color: white;
}
.vip-tierdesktop {
  padding-left: 10% !important;
  padding-right: 10% !important;
  //background-color: #000000;
  //background: transparent linear-gradient(to left, #005A54 0%, #002A20 31%, #000000 100%) 0% 0% no-repeat padding-box;
  //
  //background-repeat: no-repeat;
  //background-origin: padding-box;

  padding: 20px;
  color: white;
}
.vip-level-upgrade-info {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: white;
}
.vip-level-upgrade-info-inner {
  background-image: url('/static/svg/vipassets/How_to_Join_MobileBg.jpg');
  background-size: contain;
  background-position-y: bottom;
  height: 590px;
  width: 100%;
  padding: 15px;
  border: 2px solid #ff860c;
  border-radius: 5px;
}
.vip-level-upgrade-info-inner-desktop {
  background-image: url('/static/svg/vipassets/How_to_Join_DesktopBg.jpg');
  background-size: cover;
  height: 400px;
  width: 100%;
  padding: 15px;
  border: 2px solid #ff860c;
  border-radius: 5px;
}
.be-kk-vip {
  //background-color: #060936;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 24px !important;
  padding-top: 20px;
}
.vip-benefits {
  //background-color: #060936;
  color: #ff860c;
  padding-top: 20px;
  margin-bottom: 16px;
}
.upgrade-requirement {
  //background-color: #060936;
  color: #ff860c;
  margin-bottom: 16px;
}
.retention-requirement {
  //background-color: #060936;
  color: #ff860c;
}
.pp {
  font-size: 12px;

  &.club {
    padding-top: 5px;
    color: #c16d2d !important;
    text-transform: none;
  }
  &.diamond {
    padding-top: 5px;
    color: #b4b5b5 !important;
    text-transform: none;
  }
  &.heart {
    padding-top: 5px;
    color: #d9974a !important;
    text-transform: none;
  }
  &.spade {
    padding-top: 5px;
    color: #66c6ce !important;
    text-transform: none;
  }
  &.ace {
    padding-top: 5px;
    color: #ca8be1 !important;
    text-transform: none;
  }
  &.raja {
    padding-top: 5px;
    color: #ffffff !important;
    text-transform: none;
  }
}

.lefttext {
  text-align: left;
  color: white;
  font-size: 12px;
}
.lefttextdesktop {
  text-align: left;
  color: white;
  font-size: 12px;
}
.righttext {
  text-align: right;
  color: #ffffff;
  font-size: 12px;
}
.righttextdesktop {
  text-align: center;
  color: white;
  font-size: 12px;
}
.vip-level-upgrade-info p,
.be-kk-vip p {
  font-size: 12px;
}
.blue-text {
  color: #0047ff;
}
.progress-details-col {
  font-size: 15px;
}
.tier-all-png {
  height: auto !important;
  min-width: 48px !important;
  width: 70px !important;
  margin: 7px 11px 0px;
  display: flex;
  flex-direction: column;
}
.tier-all-png-desktop {
  border-radius: 0px;
  overflow: inherit;
  width: 95px !important;
  //height: 90px !important;
  margin: 43px 2.7% 0px;
  display: flex;
  flex-direction: column;
}
.faqspan {
  font-size: 15px;
}
.headertext {
  font-size: 18px;
  color: rgb(255, 158, 34);
  text-align: center;
  padding-bottom: 20px;
}
//@media (max-width: 1600px) {
//  .vipcard_asset{
//    height: 200px;
//    width: 200px;
//  }
//  .tier-all-png-desktop {
//    width: 135px !important;
//    height: 80px !important;
//    margin: 3px 2px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 120px !important;
//    min-width: 48px !important;
//    width: 140px !important;
//    margin: 7px 6px 0px;
//  }
//}
//@media (max-width: 1500px){
//  .tier-all-png-desktop {
//    width: 115px !important;
//    height: 74px !important;
//    margin: 3px 4px 0px;
//  }
//  .vip-all-tier-desktop {
//    height: 105px !important;
//    min-width: 48px !important;
//    width: 125px !important;
//    margin: 7px 6px 0px;
//  }
//}
//
//
@media (min-width: 1600px) {
  .height-of-tier {
    height: 400px;
  }
}
@media (min-width: 2000px) {
  .height-of-tier {
    height: 450px;
  }
}

@media (max-width: 1500px) {
  .tier-all-png-desktop {
    width: 102px !important;
    //height: 60px !important;
    margin: 43px 13px 0px;
  }
  .vip-all-tier-desktop {
    height: auto !important;
    max-width: 100px !important;
    margin: 7px 22px 0px;
  }
}
@media (max-width: 1400px) {
  .tier-all-png-desktop {
    width: 96px !important;
    //height: 55px !important;
    margin: 43px 9px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 17px 0px;
  }
}
//
//@media (max-width: 1280px){
//  .tier-all-png-desktop {
//    width: 106px !important;
//    height: 60px !important;
//    margin: 3px 3px 0px;
//  }
//}
@media screen and (max-width: 1280px) {
  .tier-all-png-desktop {
    width: 90px !important;
    //height: 50px !important;
    margin: 53px 9px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 13px 0px;
  }
  .tier-img {
    height: auto !important;
    max-width: 80px !important;
  }
}

@media (max-width: 1190px) {
  .tier-all-png-desktop {
    width: 72px !important;
    //height: 43px !important;
    margin: 53px 11px 0px;
  }
  .vip-all-tier-desktop {
    //height: 144px !important;
    //width: 105px !important;
    margin: 7px 12px 0px;
    max-width: 90px !important;
  }
}

@media (max-width: 1095px) {
  .text-vip {
    left: 30%;
  }
  .title2 {
    margin-bottom: 0px !important;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //height: 51px !important;
    margin: 53px 10px 0px;
  }
  .tier-img {
    max-width: 77px !important;
  }
  .vip-all-tier-desktop {
    max-width: 83px !important;
  }
  .infospace {
    width: 65%;
  }
}
@media (max-width: 1000px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    //height: 43px !important;
    margin: 53px 6px 0px;
  }
  .tier-img {
    max-width: 65px !important;
  }
  .infospace {
    width: 70%;
  }
  .vip-all-tier-desktop {
    height: 106px !important;
    width: 77px !important;
    margin: 10px 11px 7px;
  }
}
@media (max-width: 950px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 70px !important;
    //height: 43px !important;
    margin: 53px 5px 0px;
  }
  .vip-all-tier-desktop {
    margin: 7px 8px 0px;
  }
}
@media (max-width: 900px) {
  .infospace {
    width: 80%;
  }
  .vip-level-upgrade-info-inner-desktop {
    width: 100%;
  }
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .tier-all-png-desktop {
    width: 57px !important;
    //height: 35px !important;
    margin: 53px 5px 0px;
  }
  .tier-img {
    max-width: 59px !important;
  }
  .vip-all-tier-desktop {
    //height: 60px !important;
    //min-width: 48px !important;
    //width: 70px !important;
    margin: 7px 4px 0px;
  }
  .text-vip {
    left: 35%;
    font-size: 25px;
  }
}
@media (max-width: 800px) {
  .vipcard_asset {
    height: 200px;
    width: 200px;
  }
  .infospace {
    width: 100%;
  }
  .vip-all-tier-desktop {
    height: 92px !important;
    min-width: 48px !important;
    width: 67px !important;
    margin: 7px 4px 0px;
  }
  .tier-all-png-desktop {
    width: 50px !important;
    //height: 29px !important;
    margin: 53px 5px 0px;
  }
  .tier-img {
    max-width: 48px !important;
  }
}

@media (max-width: 540px) {
  .title3 {
    font-size: 16px;
  }
  .title2 {
    font-size: 16px;
  }
  .titlewithbackgroundcolor {
    font-size: 16px;
  }
  .h1 {
    font-size: 16px;
  }
}
@media (max-width: 490px) {
  .title3,
  .faqspan {
    font-size: 14px;
  }
  .title2 {
    font-size: 14px;
  }
  .titlewithbackgroundcolor {
    font-size: 14px;
  }
  .headertext {
    font-size: 14px;
  }
  .lefttext,
  .righttext,
  .vip-level-upgrade-info p,
  .be-kk-vip p {
    font-size: 12px;
  }
  .pp {
    font-size: 10px;
  }
  .btnleft {
    height: 30px !important;
    min-width: 50px !important;
  }
  .btnright {
    height: 30px !important;
    min-width: 50px !important;
  }
}

@media (max-width: 440px) {
  .vip-tier,
  .titlewithbackgroundcolor {
    padding: 8px;
  }
  .tierpng {
    height: 140px !important;
    min-width: 48px !important;
    width: 101px !important;
  }
  //.lefttext, .righttext ,.vip-level-upgrade-info p,
  //.be-kk-vip p, .pp {
  //  font-size: 9px;
  //}
  .title3,
  .faqspan {
    font-size: 12px;
  }
  .vip-level-upgrade-info-inner {
    height: 780px;
  }
  .titlewithbackgroundcolor {
    font-size: 12px;
  }
  .headertext {
    font-size: 12px;
  }
  .title2 {
    font-size: 12px;
  }
  .tier-all-png {
    margin: 7px 2% 0px;
  }
  .btnleft {
    height: 25px !important;
    min-width: 45px !important;
  }
  .btnright {
    height: 25px !important;
    min-width: 45px !important;
  }
}

@media (max-width: 375px) {
  //.lefttext, .righttext ,.vip-level-upgrade-info p,
  //.be-kk-vip p, .pp {
  //  font-size: 7.7px;
  //}
  //.tierpng {
  //  height: 115px !important;
  //  min-width: 48px !important;
  //  width: 135px !important;
  //}
  .btnleft {
    height: 20px !important;
    min-width: 40px !important;
  }
  .btnright {
    height: 20px !important;
    min-width: 40px !important;
  }
  .tier-all-png {
    margin: 7px 1% 0px;
    width: 39px !important;
  }
  .progress-details-col {
    font-size: 15px;
  }
}
@media (max-width: 280px) {
  .tier-all-png {
    height: auto !important;
    min-width: 35px !important;
    width: 35px !important;
    margin: 7px 1% 0px;
  }
  .btnleft {
    height: 15px !important;
    width: 20px !important;
  }
  .btnright {
    height: 15px !important;
    width: 20px !important;
  }
  .tierpng {
    height: 83px !important;
    min-width: 48px !important;
    width: 98px !important;
  }
  .vip-level-upgrade-info-inner {
    height: 834px;
  }
}
</style>
