<template>
  <div class="vip-layout-background" :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : 'px-2'">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'vipLayout',
    data: () => ({}),
    methods: {
      openLoginDialog() {
        this.$parent.openLoginDialog()
      },
      openRegisterDialog() {
        this.$parent.openRegisterDialog()
      }
    }
  }
</script>

<style lang="scss">
.vip-layout-background{
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  /*background: url("/static/image/GrabCoins/icon/bj.png") no-repeat top left;*/
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  background-size: 100%;
  background: url('/static/image/background/kk-vip-background.jpeg');
  background-size: cover;
}
</style>
