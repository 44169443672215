<template>
  <!--  <div v-style="{ content: slidervalue, background: 'linear-gradient(to right, #FFAB2D,#FF7700)', color: 'white', display: 'flex', fontSize: '10px', width: '45px', height: '45px', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }" class="v-slider__thumb-after">-->
  <div>
    <v-style>
      .v-slider__thumb.primary:after{ content: "{{ slidervalue }}";
      <!--      background: linear-gradient(to right, #FFAB2D,#FF7700);-->
      background-image: url(/static/image/icon/vip-level-no2.png); background-size: contain; color: white; display: flex; font-size:10px; width:45px; height:45px; justify-content: center; align-items:
      center; border-radius: 10px; }
    </v-style>
    <button @click="scrollToTop" class="fixed-button"><img src="/static/image/icon/goUp.svg" height="60" width="60" /></button>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-card class="vip-card justify-center" style="max-width: 1400px;">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-card class="vip-progress-card mt-8" elevation="0" v-if="isLoggedIn">
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-center mb-1 ml-2">
                    <label class="white--text font-weight" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">
                      {{ $t(`label.bettingPassTitle`) }}
                      <br />
                      {{ $t(`label.bettingPassWelcome`) }}
                      {{ memberInfo.name ? memberInfo.name.toUpperCase() : memberInfo.memberCode.toUpperCase() }}
                    </label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <template>
              <v-container class="slider-container">
                <v-row class="row-slider-container">
                  <v-col cols="9">
                    <v-slider class="custom-Slider" v-model="slidervalue" :max="slidermaxValue" readonly thumb-label></v-slider>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <v-col cols="9" lg="5" style="display: flex; justify-content: center; " class=" pa-2 ma-auto white--text" :value="vipProgressPercentage">
              <label class="negavitivemargintb" v-if="vipProgress.member_progress_type == 2">({{ vipProgress.current_deposit_progress }} / {{ vipProgress.required_deposit_amount }})</label>
              <label class="negavitivemargintb" v-if="vipProgress.member_progress_type == 3">({{ vipProgress.current_rollover_progress }} / {{ vipProgress.required_account_rollover }})</label>
            </v-col>

            <v-row justify="center" no-gutters class="px-8 pt-4 pb-4">
              <v-col class="text-center" cols="6" :class="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xlOnly ? 'pl-10' : 'pl-5') : 'pl-2'">
                <app-button class="dialog-button mr-2" :disabled="!vipProgress.has_pending_claim" :action="claimVipProgress" :title="$t(`button.claimVip`)"></app-button>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="vip-progress-card mt-8" elevation="0" v-else>
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-center">
                    <label class="white--text font-weight" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">
                      {{ $t(`label.viptitle`) }}
                      <br />
                      {{ $t(`label.vipLogin`) }}
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col class="mb-6 text-center my-8" sm="5" cols="6">
                    <app-button
                      :customClass="'font:normal normal 600 14px/21px Poppins letter-spacing: 0.7px pa-2 theme-button embedded-register-button white--text'"
                      :action="this.openRegisterDialog"
                      :title="$t(`label.uppercasejoin`)"
                    ></app-button>
                  </v-col>
                  <v-col cols="6" sm="5" class="mb-6 'font:normal normal 600 14px/21px Poppins letter-spacing: 0.7px' text-center">
                    <v-btn height="auto" @click="this.openLoginDialog" class="pa-2" :class="'embedded-login1-button  white--text font:normal normal 600 14px/21px Poppins letter-spacing: 0.7px '">
                      {{ $t(`button.login`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="vip-progress-card-2 mt-8 ">
        <v-row class="labelrow white--text cols" justify="center">
          <v-col cols="4" class="text-center">{{ $t(`label.levelvip`) }}</v-col>
          <v-col cols="4" class="text-center">{{ $t(`label.rewardvip`) }}</v-col>
          <v-col cols="4" class="text-center">{{ $t(`label.requirementvip`) }}</v-col>
        </v-row>
      </v-card>
      <div class="vipwrapper">
        <v-card v-if="isLoggedIn" class="card-ifLoggedIn">
          <!--                      'vip.progress-special': isSpecialLevel
                   :id="`reward_` + (reward.level + 1)"                        style="padding: 0px !important; margin-top: 10px !important; margin-bottom: 10px !important;"             @click="reward.level"-->
          <v-row no-gutters class="justify-center">
            <v-list-item
              elevation="0"
              class="list-vip"
              v-for="(reward, index) in currrentVipSummary"
              :key="reward.level"
              :id="`reward_` + (reward.level + 1)"
              :class="{ greater: reward.level > currentLvlCode, less: reward.level < currentLvlCode, equal: reward.level === currentLvlCode }"
            >
              <template v-if="reward.level == currentLvlCode">
                <v-row no-gutters class="vip-progress-card-exact align-center elevation">
                  <!--                  <v-col  class="challengelevel" cols="4" style="justify-content: center;align-items: center;" >-->
                  <v-col
                    cols="4"
                    class="v-avatar exactlevelicon"
                    style="
                  height: 55px;
                  min-width: 55px;
                  width: 55px;"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-icon.png" />
                    <span class="centered" style="color: transparent">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>

                  <!--                  :class=" currentLvlCode == reward.level ? 'selected-level content' : 'content' "-->
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle;justify-content: center; font-weight: bolder; font-size: 15px;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else>&nbsp;</span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ reward.text }} {{ $t(`label.bonus`) }}
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }} {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span class="exactvip" style="font-size: 20px" v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="predefinePrizeOnIndex.includes(reward.level - 1)">
                <v-row no-gutters class="vip-progress-card-special align-center elevation">
                  <!--                  <v-col  class="challengelevel" cols="4" style="justify-content: center;align-items: center;" >-->
                  <!--                    &lt;!&ndash;                  <img src="/static/svg/mobileMenu/blue1.png" />&ndash;&gt;-->
                  <!--                    <div class="levelicon">-->
                  <!--                      <span>{{ reward.level }}</span>-->
                  <!--                    </div>-->
                  <v-col
                    cols="4"
                    class="v-avatar otherlevelicon"
                    style="
                  height: 45px;
                  min-width: 45px;
                  width: 45px;  color: #FFFFFF"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-no.png" />
                    <span class="centered">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>
                  <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  <!--                  :class=" currentLvlCode == reward.level ? 'selected-level content' : 'content' "-->
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle;justify-content: center; font-weight: bolder; font-size: 15px;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'special-bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else>&nbsp;</span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'special-bold-prize-text' : 'text-center'">
                        {{ reward.text }} {{ $t(`label.bonus`) }}
                        <!--                            {{ reward.text }}-->
                        <!--                            <br />-->
                        <!--                            {{$t(`label.bonus`) }}-->
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'special-bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span style="font-size: 18px" v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-else-if="reward.level < currentLvlCode">
                <!-- Content for less class -->
                <v-row no-gutters class="vip-progress-card-before align-center elevation">
                  <v-col
                    cols="4"
                    class="v-avatar otherlevelicon"
                    style="
                  height: 45px;
                  min-width: 45px;
                  width: 45px;  color: #FFFFFF"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-no.png" />
                    <span class="centered">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle;justify-content: center;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}

                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else>&nbsp;</span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ reward.text }}
                        {{ $t(`label.bonus`) }}
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>

              <template v-else>
                <!-- Content for after class mt-5 mb-5 removed from down class-->
                <v-row no-gutters class="vip-progress-card-after align-center  elevation">
                  <!--                  <v-col  class="challengelevel" cols="4" style="justify-content: center;align-items: center;" >-->
                  <!--                    &lt;!&ndash;                  <img src="/static/svg/mobileMenu/blue1.png" />&ndash;&gt;-->
                  <!--                    <div class="levelicon">-->
                  <!--                      <span>{{ reward.level }}</span>-->
                  <!--                    </div>-->

                  <!--                    &lt;!&ndash;                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>&ndash;&gt;-->
                  <!--                  </v-col>-->
                  <v-col
                    cols="4"
                    class="v-avatar otherlevelicon"
                    style="
                  height: 45px;
                  min-width: 45px;
                  width: 45px;  color: #FFFFFF"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-no.png" />
                    <span class="centered">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>
                  <!--                  :class=" currentLvlCode == reward.level ? 'selected-level content' : 'content' "-->
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle;justify-content: center;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else>&nbsp;</span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ reward.text }}
                        {{ $t(`label.bonus`) }}
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <!--                  reward icon only testing-->
                  <!--                  -->
                  <!--                  <v-col cols="4"><v-img class="icon-size mb-2 mx-auto" :src="getDisplayIcon(reward)"-->
                  <!--                                         v-bind="attrs"-->
                  <!--                                         v-on="on"></v-img>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col cols="4" >-->
                  <!--                    <v-col :class="  currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''" >-->
                  <!--                      <app-button-->
                  <!--                          class="mb-2 mx-auto"-->
                  <!--                          :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"-->
                  <!--                          v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"-->
                  <!--                          :action="claimVipProgress"-->
                  <!--                          :title="$t(`label.claim`)"-->
                  <!--                      ></app-button>-->
                  <!--                      <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>-->
                  <!--                    </v-col>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col >-->
                  <!--                    {{ $t(`label.requirement`) }}-->
                  <!--    :class="currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''"              </v-col>-->
                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-list-item>
          </v-row>
        </v-card>
        <v-card v-else style="background-color: transparent; max-width: 1400px; margin-left: 20px; margin-right: 20px;">
          <!--                :id="`reward_` + (reward.level + 1)"-->
          <v-row no-gutters class="justify-center">
            <v-list-item
              style="padding: 0px !important; margin-top: 10px !important; margin-bottom: 10px !important;"
              class="list-vip"
              elevation="0"
              v-for="(reward, index) in currrentVipSummary"
              :key="reward.level"
              :id="`reward_` + (reward.level + 1)"
              @click="reward.level"
            >
              <template v-if="predefinePrizeOnIndex.includes(reward.level - 1)">
                <v-row no-gutters class="vip-progress-card-special  elevation" style="align-items: center">
                  <v-col
                    cols="4"
                    class="v-avatar otherlevelicon"
                    style="
                  height: 45px;
                  min-width: 45px;
                  width: 45px;  color: #FFFFFF"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-no.png" />
                    <span class="centered">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>
                  <!--                  :class=" currentLvlCode == reward.level ? 'selected-level content' : 'content' "-->
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle;justify-content: center; font-weight: bolder; font-size: 15px;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <!--                            <div class="gift-img-wrapper">-->
                            <!--                              <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>-->

                            <!--                            </div>-->
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <br />
                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else></span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ reward.text }}
                        <br />
                        {{ $t(`label.bonus`) }}
                        <!--                            {{ reward.text }}-->
                        <!--                            <br />-->
                        <!--                            {{$t(`label.bonus`) }}-->
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <br />
                        {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <!--                  reward icon only testing-->
                  <!--                  -->
                  <!--                  <v-col cols="4"><v-img class="icon-size mb-2 mx-auto" :src="getDisplayIcon(reward)"-->
                  <!--                                         v-bind="attrs"-->
                  <!--                                         v-on="on"></v-img>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col cols="4" >-->
                  <!--                    <v-col :class="  currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''" >-->
                  <!--                      <app-button-->
                  <!--                          class="mb-2 mx-auto"-->
                  <!--                          :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"-->
                  <!--                          v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"-->
                  <!--                          :action="claimVipProgress"-->
                  <!--                          :title="$t(`label.claim`)"-->
                  <!--                      ></app-button>-->
                  <!--                      <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>-->
                  <!--                    </v-col>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col >-->
                  <!--                    {{ $t(`label.requirement`) }}-->
                  <!--    :class="currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''"              </v-col>-->
                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span style="font-size: 18px" v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row no-gutters class="vip-progress-card-after elevation" style="align-items: center;">
                  <v-col
                    cols="4"
                    class="v-avatar otherlevelicon"
                    style="
                  height: 45px;
                  min-width: 45px;
                  width: 45px; color: #FFFFFF"
                  >
                    <!--                  <img src="/static/svg/mobileMenu/blue1.png" />-->
                    <!--                    <div class="levelicon">-->
                    <!--                      <span>{{ reward.level }}</span>-->
                    <!--                    </div>-->
                    <img src="/static/image/icon/vip-level-no.png" />
                    <span class="centered">{{ reward.level }}</span>

                    <!--                  <img src="../../../public/static/svg/mobileMenu/blue1.png" height="48" width="47"/>-->
                  </v-col>

                  <!--                  :class=" currentLvlCode == reward.level ? 'selected-level content' : 'content' "-->
                  <v-col cols="4" style="text-align: center;  display:flex;vertical-align: middle; justify-content: center;">
                    <v-row style="align-self: center;" no-gutters>
                      <v-col cols="4" class="text-center ">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img class="icon-size mb-1 mx-auto" :src="getDisplayIcon(reward)" v-bind="attrs" v-on="on"></v-img>
                          </template>
                          <span>{{ getFreeGiftAlt(reward) }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'COINS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        <span v-if="reward.text != '0'">{{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}</span>
                        <span v-else>&nbsp;</span>
                      </v-col>
                      <v-col cols="12" class="text-center white--text" v-if="reward.reward_type == 'BONUS'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ reward.text }}
                        {{ $t(`label.bonus`) }}
                      </v-col>
                      <v-col cols="12" class="text-center gift-text white--text" v-if="reward.reward_type == 'GIFT'" :class="(index + 1) % 5 === 0 ? 'bold-prize-text' : 'text-center'">
                        {{ $t(`label.free`) }}
                        {{ reward.text }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <!--                  reward icon only testing-->
                  <!--                  -->
                  <!--                  <v-col cols="4"><v-img class="icon-size mb-2 mx-auto" :src="getDisplayIcon(reward)"-->
                  <!--                                         v-bind="attrs"-->
                  <!--                                         v-on="on"></v-img>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col cols="4" >-->
                  <!--                    <v-col :class="  currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''" >-->
                  <!--                      <app-button-->
                  <!--                          class="mb-2 mx-auto"-->
                  <!--                          :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"-->
                  <!--                          v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"-->
                  <!--                          :action="claimVipProgress"-->
                  <!--                          :title="$t(`label.claim`)"-->
                  <!--                      ></app-button>-->
                  <!--                      <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>-->
                  <!--                    </v-col>-->
                  <!--                  </v-col>-->
                  <!--                  <v-col >-->
                  <!--                    {{ $t(`label.requirement`) }}-->
                  <!--    :class="currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''"              </v-col>-->
                  <v-col class=" text-center content white--text">
                    <app-button
                      :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                      v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                      :action="claimVipProgress"
                      :title="$t(`label.claim`)"
                    ></app-button>
                    <span style="font-size: 18px" v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-list-item>
          </v-row>
        </v-card>
      </div>
      <!--      <v-row no-gutters>-->
      <!--        <v-col cols="12" class="text-center mb-6" v-if="pagination.totalPage > 1">-->
      <!--          <v-pagination-->
      <!--            dark-->
      <!--            circle-->
      <!--            class="vip_summary_pagination"-->
      <!--            v-model="pagination.currentPage"-->
      <!--            :length="pagination.totalPage"-->
      <!--            @input="changePage"-->
      <!--            :next-icon="'mdi-chevron-right'"-->
      <!--            :prev-icon="'mdi-chevron-left'"-->
      <!--            :total-visible="pagination.paginationPageLimit"-->
      <!--          ></v-pagination>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-card>
  </div>
</template>

<script>
import { BONUS_LIST, VIP_BONUS_APPLY, VIP_BONUS_CHECK, VIP_BONUS_RESET, VIP_CLAIM_REWARD, VIP_RESET_CLAIM, VIP_REWARDS_SUMMARY, VOUCHER_BONUS_CHECK } from '../../store/bonus.module'
import { MEMBER_DETAIL, MEMBER_GET_VIP_PROGRESS } from '../../store/member.module'
import { VipClaimType } from '../../constants/enums'
import { ROUTE_NAME } from '../../constants/route.constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import currencySymbolMapp from 'currency-symbol-map'
import { SHARED } from '@/constants/constants'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import cloneDeep from 'lodash/cloneDeep'
import { value } from 'lodash/seq'
import { VImg } from 'vuetify/lib/components'

export default {
  name: 'vipPage',
  data: () => ({
    _vipProgress: {},
    slidervalue: 0,
    slidermaxValue: 0,
    predefinePrizeOnIndex: [84, 95, 96, 97, 98, 99, 104],
    bonusPercentage: '',

    mediaUrl: SHARED.MEDIA_SERVER_URL,
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    currrentVipSummary: [],
    searchCriteria: {
      pageNumber: 1,
      pageSize: 10,
      mobilePageSize: 10
    },
    currentCurrency: uiHelper.getCurrency(),
    currencySymbolMapp: currencySymbolMapp,
    alertShow: false,
    alertSuccess: true,
    alertMessage: ''
  }),
  components: {
    AppLoginEmbedded
  },
  computed: {
    // slidervalue() {
    //   // Update the value of --slidervalue CSS variable
    //   this.$refs.sliderThumb.style.setProperty('--slidervalue', this.$store.state.member.vipProgress.current_deposit_progress);
    //
    //   // Return the value of current_deposit_progress
    //   return this.$store.state.member.vipProgress.current_deposit_progress;
    // },
    computedThumbContent() {
      return this.slidervalue
    },
    thumbContent() {
      return this.slidervalue
    },
    bonusList() {
      return this.$store.state.bonus.bonusList
    },
    isApplicable() {
      return this.$store.state.bonus.vipBonusCheckResult.data.available
    },
    applyComplete() {
      return this.$store.state.bonus.vipBonusApplyResult.complete
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipClaimComplete() {
      return this.$store.state.bonus.vipProgressClaimResult.complete
    },
    vipRewardsSummaryData() {
      return this.$store.state.bonus.vipRewardsSummary
    },
    vipClaimType() {
      switch (this.vipProgress.member_progress_type) {
        case VipClaimType.BOTH:
          return 'Rollover and Deposit'
        case VipClaimType.DEPOSIT_ONLY:
          return 'Deposit Only'
        case VipClaimType.ROLLOVER_ONLY:
          return 'Rollover Only'
      }
    },
    vipProgressPercentage() {
      if (this.vipProgress.member_progress_type == 2) {
        return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
      } else if (this.vipProgress.member_progress_type == 3) {
        return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    currentLvlCode() {
      return this.vipProgress.current_level_name.split(' ')[1]
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    bonusList() {
      try {
        let bonusList = cloneDeep(this.$store.state.bonus.bonusList)
        let currentMemberGroup = this.vipProgress.current_level_name
        if (typeof bonusList != 'undefined' && bonusList.length > 0) {
          let releaseTierBonus = bonusList.find(x => x.useReleaseTiers == 1)
          if (releaseTierBonus != null) {
            let selectedApplyBonusMemberGroup = releaseTierBonus.memberGroup.find(x => x.member_group.toLowerCase() == currentMemberGroup.toLowerCase())
            if (selectedApplyBonusMemberGroup != null) {
              this.bonusPercentage = '(' + selectedApplyBonusMemberGroup.percentage_given + '%' + ')'
            }
          }
        }
      } catch (err) {
        this.bonusPercentage = ''
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    currentLvlCode() {
      if (this.isLoggedIn && this.currentLvlCode) {
        const currentLvlElement = document.getElementById('reward_' + this.currentLvlCode)
        currentLvlElement.scrollIntoView({ behavior: 'smooth' })
      }
    },
    applyComplete() {
      if (this.$store.state.bonus.vipBonusApplyResult.success) {
        window.alert('VIP Bonus apply status: ' + this.$store.state.bonus.vipBonusApplyResult.success)
        this.$store.dispatch(`${VIP_BONUS_RESET}`)
        this.getVipApplicability()
      }
    },
    vipClaimComplete() {
      if (this.$store.state.bonus.vipProgressClaimResult.complete) {
        this.showVipClaimProgress(this.$store.state.bonus.vipProgressClaimResult)
      }
    },
    vipRewardsSummaryData() {
      this.renderPage()
      // this.changePage(1)
    },
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = ''
        this.alertMessage = ''
      }
    },
    isLoggedIn() {
      this.getVipProgress()
    },
    vipProgress() {
      // let depoProgress = this.$store.state.member.vipProgress.current_deposit_progress
      // this.slidermaxValue = this.$store.state.member.vipProgress.required_deposit_amount
      // this.slidervalue = depoProgress
      // debugger
      if (this.$store.state.member.vipProgress.member_progress_type === 2) {
        this.slidervalue = this.$store.state.member.vipProgress.current_deposit_progress
        this.slidermaxValue = this.$store.state.member.vipProgress.required_deposit_amount
      } else if (this.$store.state.member.vipProgress.member_progress_type === 3) {
        this.slidervalue = this.$store.state.member.vipProgress.current_rollover_progress
        this.slidermaxValue = this.$store.state.member.vipProgress.required_account_rollover
      }
    }
  },
  created() {
    //this.getVipApplicability()
    if (this.isLoggedIn) {
      this.getMemberDetail()
      this.getVipProgress()
      this.getAvailableBonus()
    }
    this.getVipRewardsSummary()
    this.metaTag()
  },
  methods: {
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    metaTag() {
      uiHelper.setMetaTag('url', 'https://www.krikya.group/krikbet/challenge')
      uiHelper.setTitle(`${locale.getMessage('meta.challenge_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.challenge_description')}`)
      uiHelper.setMetaTag('keywords', `${locale.getMessage('meta.challenge_keyword')}`)
      const currentUrl = window.location.href
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getVipApplicability() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${VIP_BONUS_CHECK}`)
      }
    },
    getVipProgress() {
      if (this.isLoggedIn) {
        this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
      }
    },
    getVipRewardsSummary() {
      this.$store.dispatch(VIP_REWARDS_SUMMARY)
    },
    renderPage() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.mobilePageSize)
      } else {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.pageSize)
      }
      this.searchCriteria.pageNumber = 1
      this.changePage(1)
    },
    changePage(targetPage) {
      // this.searchCriteria.pageNumber = targetPage
      // if (this.$vuetify.breakpoint.xsOnly) {
      //   this.currrentVipSummary = this.vipRewardsSummaryData.slice(
      //     (this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize,
      //     this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize
      //   )
      // } else {
      //   this.currrentVipSummary = this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
      // }
      this.searchCriteria.pageNumber = targetPage
      if (this.$vuetify.breakpoint.xsOnly) {
        // this.currrentVipSummary = this.vipRewardsSummaryData.slice(
        //     (this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize,
        //     this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize
        // )
        // this.currrentVipSummary = this.vipRewardsSummaryData(
        //   (this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize,
        //   this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize
        // )
        this.currrentVipSummary = this.vipRewardsSummaryData
        this.$nextTick(() => {
          if (this.isLoggedIn && this.currentLvlCode) {
            const currentLvlElement = document.getElementById('reward_' + this.currentLvlCode)
            currentLvlElement.scrollIntoView({ behavior: 'smooth' })
          }
        })
      } else {
        this.currrentVipSummary = this.vipRewardsSummaryData
        this.$nextTick(() => {
          if (this.isLoggedIn && this.currentLvlCode) {
            const currentLvlElement = document.getElementById('reward_' + this.currentLvlCode)
            currentLvlElement.scrollIntoView({ behavior: 'smooth' })
          }
        })
        // this.currrentVipSummary = this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
      }
    },
    applyVipBonus() {
      const selectedBonusId = this.$store.state.bonus.vipBonusCheckResult.data.bonusId
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: {
          bonusId: selectedBonusId
        }
      })
    },
    claimVipProgress() {
      if (this.isApplicable) {
        this.reminderDialog()
      } else {
        this.dispatchVipProgress()
      }
    },
    dispatchVipProgress() {
      this.$store.dispatch(VIP_CLAIM_REWARD)
    },
    showVipClaimProgress(result) {
      if (result.success) {
        this.openAlert(true, locale.getMessage(`message.vipBonusSuccess`))
      } else {
        this.openAlert(false, locale.getMessage(`message.vipBonusFailed`))
      }
      this.$store.dispatch(VIP_RESET_CLAIM)
      this.getVipProgress()
    },
    getDisplayIcon(reward) {
      let path = ''

      switch (reward.reward_type) {
        case 'COINS':
          path = this.getCoinPath()
          break
        case 'GIFT':
          path = reward.reward_gift ? reward.reward_gift.imgUrl : ''
          break
        case 'BONUS':
          path = this.getGiftPath()
          break
      }

      if (this.predefinePrizeOnIndex.indexOf(reward.level - 1) != -1) {
        path = reward.reward_gift.imgUrl
      }
      return path
    },
    getFreeGiftAlt(reward) {
      let alt = ''
      if (this.predefinePrizeOnIndex.indexOf(reward.level - 1) != -1) {
        alt = reward.reward_gift.name
      } else {
        alt = 'FREE COINS'
      }
      return alt
    },
    // hard code asset path
    getCoinPath() {
      return 'https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/krikya/vip/krikbet/deposit-bonus-coin.png'
    },
    getGiftPath() {
      return 'https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/krikya/vip/krikbet/free-bonus.png'
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    reminderDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.Confirmation`)
      dialog.message.push(locale.getMessage(`message.vipSpecialBonusRedeemReminder`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.dispatchVipProgress()
      })
      dialog.button.push({
        title: locale.getMessage(`button.cancel`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      let lvlWrapper = document.getElementsByClassName('vipwrapper')[0]
      if (lvlWrapper) {
        lvlWrapper.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },
  mounted() {
    this.vipProgress
  }
}
</script>

<style lang="scss">
.gift-img-wrapper {
  display: inline-flex;
}
.vip-card .custom-Slider .v-slider__track-container {
  height: 15px !important;
  border-style: solid;
  border-color: #101221;
  border-radius: 10px !important;
  border-width: 4px !important;
}

.negavitivemargintb {
  margin-bottom: -16px;
  margin-top: -16px;
}

.v-slider--horizontal .v-slider__track-container {
  height: 7px;
}

.fixed-button {
  position: fixed;
  bottom: 18%;
  right: 2%;
  z-index: 9999;
}

.card-ifLoggedIn {
  background-color: transparent !important;
  max-width: 1400px;
  margin-left: 20px;
  margin-right: 20px;
}

.list-vip {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.vip-progress-card-after {
  background: linear-gradient(to right bottom, #009454, #000000) !important;
  opacity: 1;
  height: 110px;
  padding: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.vip-progress-card-after::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 3px; /* control the border thickness */
  background: linear-gradient(to right bottom, #00ca8f, #009454);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.vip-progress-card-before {
  background: linear-gradient(to right bottom, #009454, #000000) !important;
  opacity: 1;
  height: 110px;
  padding: 0px;
  filter: grayscale(100%);
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.vip-progress-card-before::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 3px; /* control the border thickness */
  background: #6e6e6e;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.vip-progress-card-exact .icon-size {
  height: 55px;
}

.vip-progress-card-exact {
  background: linear-gradient(to right bottom, #009454, #00ca8f) !important;
  opacity: 1;
  height: 110px;
  padding: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 8px;
}

.vip-progress-card-exact::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 3px; /* control the border thickness */
  background: linear-gradient(to right bottom, #ffba00, #ff7700) !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.vip-progress-card-special {
  background: linear-gradient(to right bottom, #c26000, #000000) !important;
  opacity: 1;
  height: 110px;
  padding: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.vip-progress-card-special::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 3px; /* control the border thickness */
  background: linear-gradient(to right bottom, #ffab2d, #ff7700) !important;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.vip-progress-card-exact.levelicon {
  height: 68px;
  width: 67px;
}

.vipwrapper {
  height: 70vh;
  overflow: auto;
}

.row-slider-container {
  justify-content: center;
  padding-top: 10px;
  //padding-right:280px;
}

.slider-container {
  height: 100%;
}

.custom-Slider .v-slider__track-background.primary.lighten-3 {
  background: #858585 !important;
}

//.embedded-login-button {
//  height: 1.5rem;
//  width: 120px;
//  background: transparent !important;
//  //background-color: transparent !important;
//  border: 1px solid #ffffff;
//  color: #ffffff !important;
//}

.embedded-login1-button {
  height: 1.5rem;
  width: 120px;
  background-color: var(--v-secondary-base) !important;
  border-radius: 5px;
  opacity: 1;
  //border: 1px solid #ffffff;
  //color: #ffffff !important;
  .v-btn::before {
    background-color: transparent;
  }
}

.embedded-register-button {
  height: 34px !important;
  border-radius: 6px !important;
  background: transparent !important;
  //background: var(--v-secondary-base) !important;
  border: 2px solid #ffffff;
  //box-shadow: 0 2px 8px 0 rgba(255, 255, 255, 1), inset 0 0 3px 0 #FFFFFF !important;
  //
  //transition: box-shadow .5s ease-out .1s, background-position .3s ease-in, text-shadow .3s ease-in .1s;
  border-color: #ffffff;
  width: 120px !important;
  padding: 4px !important;

  .v-btn::before {
    background-color: transparent;
  }
}

//.embedded-register-button::before {
//  content: "";
//  position: absolute;
//  inset: 0;
//  border-radius: 5px;
//  padding: 3px; /* control the border thickness */
//  background: white !important;
//  -webkit-mask:
//    linear-gradient(#fff 0 0) content-box,
//    linear-gradient(#fff 0 0);
//  -webkit-mask-composite: xor;
//  mask-composite: exclude;
//  pointer-events: none;
//}
.bold-prize-text {
  font-weight: bolder;
  font-size: 1.2em;
}
.special-bold-prize-text {
  font-weight: bolder;
  font-size: 15px;
}
.mobile-prize-reward {
  border-bottom: 0.5px solid white;
}

.gift-text {
  width: 47px;
}

.right_progress_text {
  border-style: solid;
  border-color: #ffffff;
  border-width: 3px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.vip-summary-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;

  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }

  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }

  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}

.vip-card {
  background-color: transparent !important;
  //display: flex;
  //justify-content: center;

  .vip-progress-card {
    background-color: rgb(80 255 219 / 24%);
    padding: 0 0 !important;
    margin-left: 20px;
    margin-right: 36px !important;
    margin-bottom: 0px !important;
    margin-top: 30px !important;
    max-width: 1400px;

    .vip-progress-status-title {
      background-image: linear-gradient(to left, #fddd04, #fdb504);
      padding: 1px 4px;
    }
  }

  .vip-progress-card-2 {
    background: #5fad8b !important;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    padding: 0px;
    height: 40px;
    max-width: 1400px;
    margin-left: 20px;
    margin-right: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;
  }

  .labelrow {
    margin: 0px;
    text-align: center;
    letter-spacing: 0px;
    font: normal normal medium 14px/21px Poppins;
    padding: 0px;
  }

  .icon-size {
    height: 2em;
    width: 41px;
  }

  .vip-process-bar-icon {
    z-index: 1;

    img {
      padding: 6px;
    }
  }

  .vip-progress-bar {
    background-color: #acacac !important;
    border: 10px solid #262626;
    border-radius: 20px;
    margin-top: 8px;

    .v-progress-linear__background {
      background-color: #acacac !important;
      border-color: #acacac !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
    }
  }

  .vip-summary-table {
    background-color: #262626;
    border-radius: 20px 20px 0px 0px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-content {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 20px 20px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-mobile {
    background-color: #262626;
    border-radius: 0px;
    padding: 4px;

    .content {
      padding: 4px;
    }
  }

  .vip-summary-table-content-mobile {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px;
    padding: 8px 4px;

    .content {
      padding: 4px;
    }
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #acacac !important;
    color: rgba(0, 0, 0, 0.12) !important;
  }
}

.vip_summary_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 12px;
    }
  }

  .v-pagination__more {
    color: #fff;
  }
}

.selected-level {
  background-color: #5fad8b !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  text-align: center;
}

.embedded-login-button {
  width: 110px !important;
}

@media (max-width: 1500px) {
  .vip-card {
    font-size: 13px;
    margin-left: 0px !important;
    //margin-top: -20px !important;
    //max-width: 1100px;
  }
  .vip-progress-card {
    margin-right: 20px !important;
  }
  .vip-progress-card-2 {
    margin-right: 20px !important;
  }
  .row-setting {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .vip-card {
    font-size: 13px;
  }
  .card-ifLoggedIn {
    margin-right: 36px;
  }
}

@media (max-width: 959px) {
  .card-ifLoggedIn {
    margin-right: 36px;
  }
  .vip-mobile-login-button {
    width: 120px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .vip-mobile-register-button {
    width: 120px !important;
  }
  .vip-card {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .vip-progress-card {
    margin-right: 20px !important;
  }
  .vip-progress-card-2 {
    margin-right: 20px !important;
  }
  .custom-Slider .v-input__control {
    max-width: 800px !important;
    border-radius: 15px !important;
  }
  .custom-Slider .v-slider__track-container {
    height: 20px !important;
    border-style: solid;
    border-color: #101221;
    border-radius: 15px !important;
    border-width: 5.5px !important;
  }
  .v-slider__thumb:after {
    height: 45px !important;
    width: 45px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 599px) {
  .card-ifLoggedIn {
    margin-right: 20px;
  }
  .vip-card {
    font-size: 12px;
    background-color: #ebebeb;

    .vip-progress-card {
      background-color: rgba(80, 255, 219, 0.24);
      margin-right: 20px !important;

      .vip-progress-status-title {
        background-image: linear-gradient(to left, #fddd04, #fdb504);
        padding: 1px 4px;
      }
    }

    .vip-progress-card-2 {
      margin-right: 20px !important;
    }

    .custom-Slider .v-input__control {
      max-width: 480px !important;
      border-radius: 10px !important;
    }

    .custom-Slider .v-slider__track-container {
      height: 15px !important;
      border-style: solid;
      border-color: #101221;
      border-radius: 10px !important;
      border-width: 4px !important;
    }

    .v-slider__thumb:after {
      height: 30px !important;
      width: 30px !important;
      font-size: 7px !important;
    }

    .icon-size {
      height: 2.5rem;
      width: 60px;
    }

    .vip-process-bar-icon {
      z-index: 1;
    }

    .vip-progress-bar {
      background-color: #111111 !important;
      border: 8px solid #262626;
      border-radius: 20px;
      margin-top: 8px;

      .v-progress-linear__background {
        background-color: #111111 !important;
        border-color: #111111 !important;
        opacity: 1 !important;
      }

      .v-progress-linear__determinate {
        background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
      }
    }

    .v-btn.dialog-button {
      width: inherit !important;
      height: auto !important;
      font-size: 12px !important;
    }
  }
}
@media (max-width: 420px) {
  .embedded-login1-button {
    width: 80px;
  }
  .embedded-register-button {
    width: 80px !important;
  }
}
@media (max-width: 380px) {
  .vip-card .icon-size {
    height: 20px;
    width: 30px;
  }
  .exactlevelicon {
    height: 45px !important;
    min-width: 45px !important;
    width: 45px !important;
  }
  .otherlevelicon {
    height: 35px !important;
    min-width: 35px !important;
    width: 35px !important;
  }
  .vip-progress-card-exact .icon-size {
    height: 25px;
    width: 36px;
  }
  .v-application .text-center {
    font-size: 10px;
  }
  .exactvip {
    font-size: 13px !important;
  }
}
</style>
